<template>
  <div class="all w-full md:pt-4">
            <div class="titre">
              Identifiez-vous
            </div>

            <div class="md:mt-10 mt-5 w-full">
              <div class="sous-titre">
                Téléphone
              </div>

              <div class="md:flex input mt-4 h-full" @click="focusActiv">
                <svg-icon
                  name="benin"
                  class="h-8 cursor-pointer mr-2"
                  original
                />
                <input type="text" ref="numero" v-model="telephone" placeholder="66 43 47 66" class="h-full" maxlength="11" @keypress="isNumberOnly">
              </div>

<!--              <div class="flex mt-5 items-center">-->
<!--                <radio class="mr-3" @info="retourAgence"/>-->
<!--                <div class="sous-titre">Se connecter en tant qu'agence</div>-->
<!--              </div>-->

              <div class="md:mt-12 mt-4 w-full">
                <div
                  v-if="error !== null"
                  class="text-center error mb-2"
                >
                  {{ error }}
                </div>

                <div
                  class="button w-full md:mt-0 mt-4"
                  @click="continues"
                >
                  <div class="loader" v-if="charge === true"></div>
                  <span v-if="charge === false">
                    Continuer
                  </span>
                </div>
              </div>

              <div class="alert mt-5 mb-4">
                En continuant, vous acceptez les
                <span @click="goto('cgu')" class="cursor-pointer">conditions d’utilisation</span> et
                <span @click="goto('politique')" class="cursor-pointer"> politiques de confidentialités</span>
              </div>
{{donnee}}
            </div>
  </div>
</template>

<script>
// import radio from '../../helper/form/radio'

export default {
  name: 'Success',
  components: {
    // radio
  },
  props: {
  },
  data () {
    return {
      charge: false,
      telephone: '',
      error: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      // if (this.telephone.length === 3) {
      //   const phone = this.telephone.substr(0, 2) + ' ' + this.telephone.substr(2, 1)
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.telephone = phone
      // }
      //
      // if (this.telephone.length === 6) {
      //   const phone = this.telephone.substr(0, 5) + ' ' + this.telephone.substr(5, 1)
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.telephone = phone
      // }
      //
      // if (this.telephone.length === 9) {
      //   const phone = this.telephone.substr(0, 8) + ' ' + this.telephone.substr(8, 1)
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.telephone = phone
      // }

      if (this.telephone.length === 8) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        const phone = this.telephone.substr(0, 2) + ' ' + this.telephone.substr(2, 2) + ' ' + this.telephone.substr(4, 2) + ' ' + this.telephone.substr(6, 2)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.telephone = phone
      }
      if (this.telephone.length === 10) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.telephone = this.telephone.replace(/ /g, '')
      }
    }
  },

  methods: {
    retourAgence (answer) {
      this.$store.dispatch('saveAgence', answer)
    },

    focusActiv () {
      this.$refs.numero.focus()
    },

    goto (index) {
      // window.open('https://www.atlantiqueassurances.bj/', '_blank')
      this.$router.push('/' + index)
    },

    continues () {
      this.charge = true
      this.error = null
      const phone = this.telephone.replace(/ /g, '')
      if (phone.length === 8) {
        this.$emit('info', phone)
      } else {
        this.charge = false
        this.error = 'Veillez entrer un numero de téléphone valid pour continuer'
      }
    },

    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../../assets/style/sass/mixin";

    .alert{
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      color: #000000;
      span{
        text-decoration-line: underline;
        color: #0269AD;
        cursor: pointer;
      }
    }
    .error{
      font-size: 14px;
      color: red;
    }
    .titre{
      font-weight: 500;
      font-size: 36px;
      line-height: 48px;
      color: #000000;
    }
    .sous-titre{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #909090;
    }
    .input{
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 10px;
      height: 60px;
    }
    .button{
      background-color: $base-color;
      height: 60px;
      font-weight: normal;
      font-size: 24px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .titre{
        font-size: 26px;
      }
      .sous-titre{
        font-size: 16px;
      }
      .input{
        height: 49px;
      }
      .button{
        height: 49px;
        font-size: 20px;
      }
    }

    @media screen and (max-width: 700px){
      .titre{
        font-size: 24px;
      }
      .sous-titre{
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }
      .input{
        height: 47px;
      }
      .button{
        height: 47px;
        font-size: 20px;
      }
      .alert{
        text-align: center;
      }
      .all{
        padding-top: 100px;
      }
    }
</style>
