<template>
  <div class="all w-full md:pt-4">
            <div class="titre">
              Confirmation
            </div>

            <div class="md:mt-10 w-full">
              <div class="sous-titre">
                Entrez le code de confirmation reçu par SMS
              </div>

              <div class="flex md:w-full w-3/4 ml-auto mr-auto">
                <div class="input mt-4 w-1/4 mr-5">
                  <input type="text" v-model="code1" maxlength="01" @keypress="isNumberOnly" class="w-full h-full" id="inp1">
                </div>
                <div class="input mt-4 w-1/4 mr-5">
                  <input type="text" v-model="code2" maxlength="01" @keypress="isNumberOnly" class="w-full h-full" id="inp2">
                </div>
                <div class="input mt-4 w-1/4 mr-5">
                  <input type="text" v-model="code3" maxlength="01" @keypress="isNumberOnly" class="w-full h-full" id="inp3">
                </div>
                <div class="input mt-4 w-1/4">
                  <input type="text" v-model="code4" maxlength="01" @keypress="isNumberOnly" class="w-full h-full" id="inp4">
                </div>
              </div>

              <div class="cod text-center mt-4">
                Code non reçu ?
                <span @click="sendCode">
                  Renvoyer
                </span>
              </div>

              <div class="md:mt-8 w-full">
                <div
                  v-if="error !== null"
                  class="text-center error mb-2"
                >
                  {{ error }}
                </div>

                <div
                  class="button w-full md:mt-0 mt-4"
                  @click="continues"
                >
                  <div class="loader" v-if="charge === true"></div>
                  <span v-if="charge === false">
                    Continuer
                  </span>
                </div>
              </div>

              <div class="alert mt-5 mb-4">
                En continuant, vous acceptez les
                <span @click="condition" class="cursor-pointer">conditions d’utilisation et politiques de confidentialités</span>
              </div>
            </div>
    {{ controleur }}
  </div>
</template>

<script>

export default {
  name: 'Success',
  components: { },
  props: {
    error: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      charge: false,
      telephone: '',
      code1: '',
      code2: '',
      code3: '',
      code4: ''
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    controleur: function () {
      if (this.error !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.code2 = ''
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.code3 = ''
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.code4 = ''
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.charge = false
      }
      if (this.code1 === '') {

      } else {
        if (this.code2 === '') {
          document.getElementById('inp2').focus()
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.error = null
        } else {
          if (this.code3 === '') {
            document.getElementById('inp3').focus()
          } else {
            if (this.code4 === '') {
              document.getElementById('inp4').focus()
            }
          }
        }
      }
    }
  },

  methods: {
    continues () {
      this.charge = true
      this.error = null
      if (this.code1.length === 1 && this.code2.length === 1 && this.code3.length === 1 && this.code4.length === 1) {
        const code = this.code1.toString() + this.code2.toString() + this.code3.toString() + this.code4.toString()
        this.$emit('info', code)
      } else {
        this.charge = false
        this.error = 'Veillez entrer votre code de confirmation pour continuer'
      }
    },

    condition () {
      window.open('https://www.atlantiqueassurances.bj/', '_blank')
    },

    sendCode () {
      this.$emit('info', null)
    },

    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../../assets/style/sass/mixin";

    .alert{
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      color: #000000;
      span{
        text-decoration-line: underline;
        color: #0269AD;
        cursor: pointer;
      }
    }
    .cod{
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      color: #000000;
      span{
        color: #0269AD;
        cursor: pointer;
      }
    }
    .error{
      font-size: 14px;
      color: red;
    }
    input{
      text-align: center;
    }
    .titre{
      font-weight: 500;
      font-size: 36px;
      line-height: 48px;
      color: #000000;
    }
    .sous-titre{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #909090;
    }
    .input{
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 10px;
      height: 60px;
    }
    .button{
      background-color: $base-color;
      height: 60px;
      font-weight: normal;
      font-size: 24px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .titre{
        font-size: 26px;
      }
      .sous-titre{
        font-size: 16px;
      }
      .input{
        height: 49px;
      }
      .button{
        height: 49px;
        font-size: 20px;
      }
    }
    @media screen and (max-width: 700px) and (min-width: 376px){
      .titre{
        font-size: 24px;
      }
      .sous-titre{
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }
      .input{
        height: 47px;
      }
      .button{
        height: 47px;
        font-size: 20px;
      }
      .alert{
        text-align: center;
      }
      .cod{
        font-size: 12px;
        font-weight: 500;
      }
      .all{
        padding-top: 100px;
      }
    }
    @media screen and (max-width: 375px) {
      .input{
        width: 33%;
        margin-right: 5px;
      }
      .all{
        padding-top: 100px;
      }
      .titre{
        font-size: 24px;
      }
      .sous-titre{
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }
      .input{
        height: 47px;
      }
      .button{
        height: 47px;
        font-size: 20px;
      }
      .alert{
        text-align: center;
      }
      .cod{
        font-size: 12px;
        font-weight: 500;
      }
    }
</style>
