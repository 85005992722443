<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container md:flex h-full"
        >
          <div class="md:w-1/3 w-full bloc1 p-4">
            <div class="w-10 h-10 rounded-full close md:mt-0 mt-3 flex items-center cursor-pointer justify-center" @click="fermer">
              <div>X</div>
            </div>
            <div class="flex items-center justify-center">
              <img src="../../assets/images/png/sign.png" alt="" class="md:mt-20 mt-5">
            </div>
          </div>

          <div class="md:w-2/3 w-full p-5">
            <sign @info="retourPhone" v-if="position === 1"/>
            <confirm-code @info="retourCode" v-if="position === 2" :error="error"/>
            <div class="flex justify-center" v-if="charge"><div class="loader text-center"></div></div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import sign from '@/components/cedro/signup/sign'
import confirmCode from '@/components/cedro/signup/confirmCode'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import firebase from 'firebase'

export default {
  name: 'Success',
  components: { PopupBase, sign, confirmCode },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      telephone: '',
      position: 1,
      code: null,
      error: null,
      charge: false
    }
  },
  computed: {
  },

  methods: {
    fermer () {
      this.activeOk = false
      this.$emit('oga', false)
    },

    yes () {
      this.activeOk = false
      this.$emit('oga', true)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
      this.activeOk = false
    },

    retourPhone (answer) {
      this.telephone = answer
      this.verifPhone(answer)
    },

    retourCode (answer) {
      if (answer === null) {
        this.charge = true
        this.verifPhone(this.telephone)
      } else {
        this.code = answer
        this.verifCode(answer)
      }
    },

    verifPhone (phone) {
      http.get(apiroutes.baseURL + apiroutes.sign + '?telephone=229' + phone)
        .then(response => {
          console.log(response)
          this.position = 2
          this.charge = false
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
        })
    },

    verifCode (code) {
      http.post(apiroutes.baseURL + apiroutes.confirmCode + '?telephone=229' + this.telephone + '&otp=' + code)
        .then(response => {
          this.$store.dispatch('login', response.data.access_token)

          this.loginFirebase()
        })
        .catch(error => {
          this.error = 'Veuillez vérifié votre code de confirmation et ressayez'
          console.log('erreur')
          console.log(error)
        })
    },

    loginFirebase () {
      this.error = null
      // this.loginFirebase
      const pass = '@7l@nt!k'
      firebase.auth().signInWithEmailAndPassword('229' + this.telephone + '@aab.com', pass)
        .then((result) => {
          const temps = 7 * (1000 * 3600 * 24)
          const expire = new Date().getTime() + temps
          this.$store.dispatch('saveExpireSession', expire)
          this.$router.push('/home')
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 60%;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .bloc1{
      background: #F5F5F5;
      border-radius: 12px 0px 0px 12px;
    }
    .close{
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      color: #FF4B55;
    }
    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 80%;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 100%;
      }
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .bloc1{
        display: none;
      }
      .close{
        width: 42px;
        height: 42px;
        position: absolute;
      }
      .cardinal{
        width: 100%;
      }
      img{
        height: 103px;
      }
    }
</style>
